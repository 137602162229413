<template>
  <div>
    <b-row class="m-0 justify-content-center">
      <b-col class="content-header" cols="12" md="9">
        <div class="d-flex align-items-start w-100 justify-content-between">
          <div class="d-flex">
            <h2
              class="content-header-title float-left m-0 pr-1 mr-1"
              style="border-right: 1px solid #e2e2e2"
            >
              {{ $route.meta.pageTitle }}
            </h2>
            <div class="content-header-search mr-1">
              <h3 class="m-0" style="text-transform: capitalize">
                {{ affairForm.name }}
              </h3>
            </div>
          </div>
          <div class="content-header-actions d-flex">
            <feather-icon
              class="cursor-pointer"
              icon="XIcon"
              size="24"
              @click="cancel()"
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <div
      v-if="isLoadingAffair || isCreatingAffair || isUpdatingAffair"
      class="content-loader-center m-0 h-100"
    >
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement des détails de l'affaire...
        </div>
      </div>
    </div>
    <b-row
      v-else
      class="content-scrollable-sticky m-0 justify-content-center"
      style="height: calc(100% - 110px)"
    >
      <b-col cols="12" md="9" class="content-scrollable-sticky-main h-100">
        <validation-observer ref="formCreateAffair">
          <b-form autocomplete="off" class="h-100" style="overflow-x: hidden">
            <div id="general" class="my-0">
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group label="Client *" label-for="customer">
                    <validation-provider
                      #default="{ errors }"
                      name="customer"
                      rules="required"
                    >
                      <ejs-dropdownlist
                        id="customer"
                        :footerTemplate="'footerTemplate'"
                        popupHeight="300px"
                        sortOrder="Ascending"
                        filterType="Contains"
                        :allowFiltering="true"
                        :dataSource="companiesList"
                        :fields="{ text: 'name', value: 'id' }"
                        v-model="affairForm.companyId"
                      >
                        <template v-slot:footerTemplate>
                          <div class="p-50">
                            <ejs-button
                              v-on:click.native="newCompany"
                              :content="'+ Nouveau client'"
                              :isPrimary="true"
                              locale="fr"
                              :spinSettings="{ position: 'Right' }"
                              :iconCss="'e-btn-sb-icons e-icons e-open-link'"
                              cssClass="w-100"
                              iconPosition="Right"
                            />
                          </div>
                        </template>
                      </ejs-dropdownlist>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col md="12" lg="12" xl="7" class="py-0 mb-1">
                  <b-form-group label="Nom de l'affaire *" label-for="name">
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        v-model="affairForm.name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Renseigner un nom"
                        class="not-autocomplete"
                        autocomplete="nope"
                        aria-autocomplete="nope"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12" lg="6" xl="2" class="py-0 mb-1">
                  <b-form-group label-for="affairCompany" label="Code *">
                    <ejs-textbox
                      id="affairCompany"
                      placeholder="Code"
                      v-model="affairForm.code"
                      name="Code"
                      required=""
                    ></ejs-textbox>
                  </b-form-group>
                </b-col>
                <b-col md="10" lg="5" xl="2" class="py-0 mb-1">
                  <b-form-group label="Statut" label-for="status">
                    <ejs-dropdownlist
                      id="status"
                      popupHeight="300px"
                      filterType="Contains"
                      :allowFiltering="true"
                      :dataSource="statusList"
                      :fields="{ text: 'label', value: 'value' }"
                      v-model="affairForm.status"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2" lg="1" xl="1" class="py-0 mb-1">
                  <b-form-group
                    label="Couleur"
                    label-for="color"
                    class="
                      d-flex
                      flex-column
                      align-content-center align-items-center
                      select-color-group
                    "
                  >
                    <ejs-colorpicker
                      id="color"
                      v-model="affairForm.color"
                      mode="Palette"
                    ></ejs-colorpicker>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group>
                    <b-form-checkbox
                      v-model="affairForm.ownAddressAffair"
                      class="custom-control-primary"
                    >
                      L'affaire est à une adresse différente de celle du client
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <div v-if="affairForm.ownAddressAffair">
                <b-row class="my-0">
                  <b-col cols="12" class="py-0 mb-1">
                    <b-form-group label="Adresse" label-for="address">
                      <validation-provider #default="{ errors }" name="Address">
                        <vue-typeahead-bootstrap
                          type="text"
                          :state="errors.length > 0 ? false : null"
                          class="not-autocomplete"
                          id="address"
                          v-model="affairForm.address"
                          :ieCloseFix="false"
                          :data="autocompleteAddressData"
                          :serializer="(item) => item.properties.label"
                          :showAllResults="true"
                          placeholder="Rechercher une adresse ou saisissez : Rue et n° de rue"
                          @input="autocompleteAddressSource()"
                          @hit="autocompleteAddressSelected"
                          autocomplete="nope"
                          aria-autocomplete="nope"
                        >
                          <template
                            slot="suggestion"
                            slot-scope="{ data, htmlText }"
                          >
                            <div class="item">
                              <small>{{ data.properties.name }}</small>
                              <span>{{
                                data.properties.postcode +
                                " " +
                                data.properties.city
                              }}</span>
                            </div>
                          </template>
                        </vue-typeahead-bootstrap>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" class="py-0 mb-1">
                    <validation-provider
                      #default="{ errors }"
                      name="AddressComplement"
                    >
                      <ejs-textbox
                        id="addressComplement"
                        placeholder="Batiment, Appartement.."
                        v-model="affairForm.addressComplement"
                        name="AddressComplement"
                        required=""
                      ></ejs-textbox>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row class="my-0">
                  <b-col cols="3" class="py-0 mb-1">
                    <validation-provider #default="{ errors }" name="ZipCode">
                      <ejs-textbox
                        id="zipCode"
                        placeholder="Code postal"
                        v-model="affairForm.zipCode"
                        name="ZipCode"
                        required=""
                      ></ejs-textbox>
                    </validation-provider>
                  </b-col>
                  <b-col cols="9" class="py-0 mb-1">
                    <validation-provider #default="{ errors }" name="City">
                      <ejs-textbox
                        id="city"
                        placeholder="Ville"
                        v-model="affairForm.city"
                        name="City"
                        required=""
                      ></ejs-textbox>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row class="my-0">
                  <b-col cols="12" class="py-0 mb-1">
                    <validation-provider #default="{ errors }" name="Country">
                      <ejs-textbox
                        id="country"
                        placeholder="Pays"
                        v-model="affairForm.country"
                        name="Country"
                        required=""
                      ></ejs-textbox>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row class="my-0">
                  <b-col cols="12" class="py-0">
                    <label for="address'">Coordonnées GPS</label>
                  </b-col>
                  <b-col cols="3" class="py-0 mb-1">
                    <validation-provider #default="{ errors }" name="Latitude">
                      <ejs-textbox
                        id="latitude"
                        placeholder="Latitude"
                        v-model="affairForm.latitude"
                        name="Latitude"
                        required=""
                      ></ejs-textbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col cols="3" class="py-0 mb-1">
                    <validation-provider #default="{ errors }" name="Longitude">
                      <ejs-textbox
                        id="longitude"
                        placeholder="Longitude"
                        v-model="affairForm.longitude"
                        name="Longitude"
                        required=""
                      ></ejs-textbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>

                <vs-divider color="#cccccc" class="mt-0 mb-1"></vs-divider>
              </div>

              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group label="Description" label-for="description">
                    <validation-provider
                      #default="{ errors }"
                      name="Description"
                    >
                      <ejs-textbox
                        id="description"
                        placeholder="Description courte de l'affaire"
                        v-model="affairForm.description"
                        name="Description"
                        required=""
                        :multiline="true"
                      ></ejs-textbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="6" class="py-0 mb-1">
                  <b-form-group label="Catégorie" label-for="Catégorie">
                    <validation-provider #default="{ errors }" name="Catégorie">
                      <ejs-dropdowntree
                        ref="affairCategory"
                        :fields="fieldsCategories"
                        :changeOnBlur="false"
                        popupHeight="300"
                        v-model="affairForm.affairCategoryId"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6" class="py-0 mb-1">
                  <b-form-group label="Montant" label-for="Montant">
                    <validation-provider #default="{ errors }" name="Montant">
                      <ejs-numerictextbox
                        id="amount"
                        v-model="affairForm.amount"
                        format="# ###.## €"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group
                    label="Zone de l'affaire"
                    label-for="affairZone"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="affairZone"
                    >
                      <ejs-dropdownlist
                        id="affairZone"
                        popupHeight="300px"
                        sortOrder="Ascending"
                        filterType="Contains"
                        :allowFiltering="true"
                        :dataSource="zoneList"
                        :fields="{ text: 'label', value: 'value' }"
                        v-model="affairForm.zone"
                      >
                      </ejs-dropdownlist>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group
                    label="Affaire parente"
                    label-for="affairParent"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="affairParent"
                    >
                      <ejs-dropdownlist
                        id="affairParent"
                        popupHeight="300px"
                        sortOrder="Ascending"
                        filterType="Contains"
                        :allowFiltering="true"
                        :dataSource="affairParents"
                        :fields="{ text: 'label', value: 'value' }"
                        v-model="affairForm.parentAffairId"
                      >
                      </ejs-dropdownlist>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="6" class="py-0 mb-1">
                  <b-form-group label="Date de début" label-for="startDate">
                    <validation-provider #default="{ errors }" name="startDate">
                      <!-- <date-range-picker
                        ref="picker"
                        :showDropdowns='true'
                        id="startDate"
                        style="width: 100%"
                        :state="errors.length > 0 ? false : null"
                        @update="setStartDate"
                        :show-week-numbers="true"
                        :timePicker="false"
                        :opens="'right'"
                        :append-to-body="true"
                        :locale-data="config"
                        :ranges="defaultRange"
                        v-model="startDate"
                        :auto-apply="true"
                        :single-date-picker="true"
                      >
                      
                      </date-range-picker> -->
                      <ejs-datepicker
                        :format="'dd/MM/yyyy'"
                        locale="fr"
                        v-model="affairForm.startDate"
                        placeholder="JJ/MM/AAAA"
                      ></ejs-datepicker>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6" class="py-0 mb-1">
                  <b-form-group label="Date de fin" label-for="endDate">
                    <validation-provider #default="{ errors }" name="endDate">
                      <!-- <date-range-picker
                        ref="picker"
                        :showDropdowns='true'
                        id="endDate"
                        style="width: 100%"
                        :state="errors.length > 0 ? false : null"
                        @update="setEndDate"
                        :show-week-numbers="true"
                        :timePicker="false"
                        :opens="'right'"
                        :append-to-body="true"
                        :locale-data="config"
                        :ranges="defaultRange"
                        v-model="endDate"
                        :auto-apply="true"
                        :single-date-picker="true"
                        :min-date="affairForm.startDate"
                        > 
                      </date-range-picker>-->
                      <ejs-datepicker
                        :format="'dd/MM/yyyy'"
                        locale="fr"
                        v-model="affairForm.endDate"
                        placeholder="JJ/MM/AAAA"
                      ></ejs-datepicker>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group
                    label="Collaborateurs en charge de l'affaire"
                    label-for="collaborators"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Collaborators"
                    >
                      <ejs-multiselect
                        id="collaborators"
                        :footerTemplate="'footerTemplate'"
                        popupHeight="300px"
                        sortOrder="Ascending"
                        filterType="Contains"
                        :allowFiltering="true"
                        :dataSource="collaboratorsList"
                        :fields="{ text: 'displayLabel', value: 'id' }"
                        v-model="affairForm.collaboratorIds"
                      >
                        <template v-slot:footerTemplate>
                          <div class="p-50">
                            <ejs-button
                              placeholder="Selectionnez un ou plusieurs collaborateur"
                              v-on:click.native="newCollaborator"
                              :content="'+ Nouveau collaborateur'"
                              :isPrimary="true"
                              locale="fr"
                              :spinSettings="{ position: 'Right' }"
                              :iconCss="'e-btn-sb-icons e-icons e-open-link'"
                              cssClass="w-100"
                              iconPosition="Right"
                            />
                          </div>
                        </template>
                      </ejs-multiselect>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>

        <b-row class="my-0">
          <b-col cols="12" class="py-0">
            <div
              class="d-flex justify-content-between mt-2"
              style="border-top: 1px solid #e2e2e2; padding-top: 0.5rem"
            >
              <div>
                <b-button variant="outline-primary" @click="cancel()">
                  <feather-icon icon="ArrowLeftIcon" />
                  Annuler
                </b-button>
                <b-button
                  v-if="affairForm.id"
                  variant="outline-danger"
                  class="btn-icon ml-2"
                  @click="archiveAffairLocal(affairForm.id, affairForm.name)"
                >
                  <feather-icon icon="ArchiveIcon" />
                </b-button>
              </div>

              <b-button variant="primary" @click="create">
                <feather-icon icon="SaveIcon" v-if="affairForm.id" />
                <feather-icon icon="PlusIcon" v-else />
                {{ affairForm.id ? "Modifier" : "Ajouter" }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { debounce } from "lodash";
import Autocomplete from "vuejs-auto-complete";
import vSelect from "vue-select";
import { ECivility } from "@/types/api-orisis/enums/enums";
import Ripple from "vue-ripple-directive";
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";

configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

import { mapGetters, mapActions } from "vuex";
import { BFormCheckbox, BFormSpinbutton } from "bootstrap-vue";
import { mask } from "vue-the-mask";
import { affairStatus, affairZone } from "@/types/api-orisis/enums/enums";

const config = {
  direction: "ltr",
  format: "dd/mm/yyyy",
  separator: " - ",
  applyLabel: "Appliquer",
  cancelLabel: "Annuler",
  weekLabel: "S",
  customRangeLabel: "Période personnalisée",
  daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  firstDay: 1,
};
var defaultRange = {
  "Aujourd'hui": [new Date(dayjs()), new Date(dayjs())],
  Demain: [new Date(dayjs().add(1, "day")), new Date(dayjs().add(1, "day"))],
  Hier: [
    new Date(dayjs().subtract(1, "day")),
    new Date(dayjs().subtract(1, "day")),
  ],
  "Dans 1 mois": [
    new Date(dayjs().add(1, "month")),
    new Date(dayjs().add(1, "month")),
  ],
  "Dans 3 mois": [
    new Date(dayjs().add(3, "month")),
    new Date(dayjs().add(3, "month")),
  ],
  "Dans 6 mois": [
    new Date(dayjs().add(6, "month")),
    new Date(dayjs().add(6, "month")),
  ],
  "Dans 1 an": [
    new Date(dayjs().add(1, "year")),
    new Date(dayjs().add(1, "year")),
  ],
  Aucune: [null, null],
};
export default {
  props: {
    id: {
      default: null,
    },
    companyId: {
      default: null,
    },
  },
  data() {
    return {
      scrolled: false,
      autocompleteAffairName: "",
      autocompleteAffairNameData: [],
      autocompleteAddressData: [],
      autocompleteAddressIndex: 0,
      statusList: affairStatus,
      zoneList: affairZone,
      optionCivility: [
        {
          label: ECivility[ECivility.Monsieur],
          value: ECivility.Monsieur,
        },
        {
          label: ECivility[ECivility.Madame],
          value: ECivility.Madame,
        },
        {
          label: ECivility[ECivility.Autre],
          value: ECivility.Autre,
        },
      ],
      required,
      url,
      email,
      displayMore: false,
      dataOrigine: {},
      affairForm: {},
      affairType: {},
      defaultRange: defaultRange,
      config: config,
      dateRange: {
        startDate: null,
        endDate: null,
      },
    };
  },
  async created() {
    await this.getCompanies({});
    this.popupAffairEvent(this.id);
    this.getCollaborators({});
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true);
    this.deteleAutocomplete();
    this.getAffairCategoriesTree({});
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    ...mapActions([
      "createAffair",
      "getAffairById",
      "updateAffair",
      "archiveAffairs",
      "getCollaborators",
      "getCompanies",
      "getAffairCategoriesTree",
    ]),
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(
        ".content-scrollable-sticky-nav ul li a"
      );
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach((link) => {
        let section = document.querySelector(link.hash);
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    deteleAutocomplete() {
      let elements = document.querySelectorAll(".not-autocomplete input");
      if (!elements) {
        return;
      }
      elements.forEach((element) => {
        element.setAttribute("autocomplete", "nope");
      });

      elements = document.querySelectorAll(".not-autocomplete");
      if (!elements) {
        return;
      }
      elements.forEach((element) => {
        element.setAttribute("autocomplete", "nope");
      });
    },
    popupAffairEvent(id) {
      this.getCollaborators({});
      if (id !== "" && id !== null && id !== 0) {
        this.getAffairById({ affairId: id }).then((res) => {
          this.affairForm = res;
          this.affairForm.companyId = res.company.id;
          this.affairForm.collaboratorIds = this.affairForm.collaborators.map(
            (collaborator) => collaborator.id
          );
          delete this.affairForm.collaborators;
          this.dataOrigine = JSON.stringify(this.affairForm);
        });
      } else {
        this.initializeForm();
      }
    },
    initializeForm() {
      this.startDate = {
        startDate: null,
        endDate: null,
      };
      this.endDate = {
        startDate: null,
        endDate: null,
      };
      this.affairForm = {
        id: 0,
        name: null,
        description: null,
        startDate: null,
        endDate: null,
        collaboratorIds: null,
        code: null,
        address: null,
        addressComplement: null,
        zipCode: null,
        city: null,
        country: null,
        ownAddressAffair: false,
        companyId: this.companyId ? this.companyId : null,
        latitude: 0,
        longitude: 0,
        affairCategoryId: null,
      };
      this.dataOrigine = JSON.stringify(this.affairForm);
    },
    displayFormComplement() {
      this.displayMore = !this.displayMore;
      this.$nextTick(() => {
        this.deteleAutocomplete();
      });
    },
    create(e) {
      e.preventDefault();
      const codeAlreadyExist = this.affairsList.find(
        (affair) =>
          affair.code === this.affairForm.code &&
          affair.id !== this.affairForm.id
      );
      if (this.affairForm.code === null || this.affairForm.code === "") {
        this.affairForm.code = null;
        this.$bvModal
          .msgBoxConfirm(
            "Sans code affaire, il vous sera plus difficile d'analyser vos données.",
            {
              title:
                "Vous n'avez pas renseigné de code affaire. Êtes-vous sûr de vouloir continuer ?",
              size: "sm",
              okTitle: "Continuer",
              okVariant: "primary",
              cancelTitle: "Annuler",
              cancelVariant: "outline-danger",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.$refs.formCreateAffair
                .validate()
                .then((success1) => {
                  if (success1) {
                    if (this.affairForm.id)
                      this.updateAffair({
                        affair: this.affairForm,
                        route: "affair",
                      }).then(() => {
                        this.$nextTick(() => {
                          if (
                            this.$route.params.routeOrigine &&
                            this.$route.params.routeOrigine !== ""
                          ) {
                            this.$tabs.close();
                          } else {
                            this.$tabs.close({ to: "/affairs" });
                          }
                        });
                      });
                    else
                      this.createAffair({
                        affair: {
                          ...this.affairForm,
                          affairCategoryId:
                            this.affairForm.affairCategoryId &&
                            this.affairForm.affairCategoryId.length
                              ? this.affairForm.affairCategoryId[0]
                              : null,
                        },
                        route: "affair",
                      }).then(() => {
                        this.$nextTick(() => {
                          if (
                            this.$route.params.routeOrigine &&
                            this.$route.params.routeOrigine !== ""
                          ) {
                            this.$tabs.close();
                          } else {
                            this.$tabs.close({ to: "/affairs" });
                          }
                        });
                      });
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }
          });
      } else if (codeAlreadyExist) {
        this.$bvModal.msgBoxConfirm(
          this.affairForm.code
            ? "Veuillez mettre un autre code affaire."
            : "Sans code affaire, il vous sera plus difficile d'analyser vos données.",
          {
            title: this.affairForm.code
              ? `Le code affaire ${this.affairForm.code} existe déjà.`
              : "Vous n'avez pas renseigné de code affaire. Êtes-vous sûr de vouloir continuer ?",
            size: "sm",
            okTitle: "D'accord",
            okVariant: "primary",
            cancelTitle: "Fermer",
            cancelVariant: "outline-danger",
            hideHeaderClose: true,
            centered: true,
          }
        );
      } else {
        this.$refs.formCreateAffair
          .validate()
          .then((success1) => {
            if (success1) {
              if (this.affairForm.id)
                this.updateAffair({
                  affair: this.affairForm,
                  route: "affair",
                }).then(() => {
                  this.$nextTick(() => {
                    if (
                      this.$route.params.routeOrigine &&
                      this.$route.params.routeOrigine !== ""
                    ) {
                      this.$tabs.close();
                    } else {
                      this.$tabs.close({ to: "/affairs" });
                    }
                  });
                });
              else
                this.createAffair({
                  affair: {
                    ...this.affairForm,
                    affairCategoryId:
                      this.affairForm.affairCategoryId &&
                      this.affairForm.affairCategoryId.length
                        ? this.affairForm.affairCategoryId[0]
                        : null,
                  },
                  route: "affair",
                }).then(() => {
                  this.$nextTick(() => {
                    if (
                      this.$route.params.routeOrigine &&
                      this.$route.params.routeOrigine !== ""
                    ) {
                      this.$tabs.close();
                    } else {
                      this.$tabs.close({ to: "/affairs" });
                    }
                  });
                });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },

    // Autocomplete Address from data.gouv
    autocompleteAddressSource: debounce(function (indexSource) {
      let _this = this;
      fetch(
        `https://api-adresse.data.gouv.fr/search/?q=${this.affairForm.address}&autocomplete=0&limit=50`
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.autocompleteAddressData = data.features;
          this.autocompleteAddressIndex = indexSource;
        });
    }, 500),
    autocompleteAddressSelected(item) {
      this.affairForm.address = item.properties.name;
      this.affairForm.city = item.properties.city;
      this.affairForm.zipCode = item.properties.postcode;
      this.affairForm.latitude = item.geometry.coordinates[1];
      this.affairForm.longitude = item.geometry.coordinates[0];
      this.affairForm.country = "France";
    },
    setStartDate(dates) {
      if (dates.startDate != null && dates.endDate != null) {
        this.affairForm.startDate = new Date(dates.startDate).toISOString();
      } else {
        this.affairForm.startDate = null;
      }
    },
    setEndDate(dates) {
      if (dates.startDate != null && dates.endDate != null) {
        this.affairForm.endDate = new Date(dates.endDate).toISOString();
      } else {
        this.affairForm.endDate = null;
      }
    },
    // Controls from
    archiveAffairLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveAffairs({ affairIds: [id], roue: "affair" });
            this.$tabs.close();
          }
        });
    },
    cancel() {
      if (this.dataOrigine == JSON.stringify(this.affairForm)) {
        this.$tabs.close();
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.$tabs.close();
            }
          });
      }
    },
    newCompany() {
      this.$router.push({ path: "/directory/new-company/clients/5" });
    },
    newCollaborator() {
      if (this.affairForm.id) {
        this.$router.push({
          name: "new-collaborator",
          params: { routeOrigine: "edit-affair/" + this.affairForm.id },
        });
      } else {
        this.$router.push({
          name: "new-collaborator",
          params: { routeOrigine: "new-affair/" },
        });
      }
    },
  },
  watch: {
    "affairForm.ownAddressAffair": function (newVal, oldVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.deteleAutocomplete();
        });
      }
    },
  },
  computed: {
    startDate: {
      get() {
        return {
          startDate: this.affairForm.startDate,
          endDate: this.affairForm.startDate,
        };
      },
      set(value) {
        this.affairForm.startDate = value.startDate;
      },
    },
    endDate: {
      get() {
        return {
          startDate: this.affairForm.endDate,
          endDate: this.affairForm.endDate,
        };
      },
      set(value) {
        this.affairForm.endDate = value.endDate;
      },
    },
    fieldsCategories() {
      return {
        dataSource: this.affairCategoriesTreeList,
        value: "id",
        text: "label",
        child: "children",
      };
    },
    affairParents() {
      return this.affairsList
        .map((affair) => {
          return {
            label: affair.name,
            value: affair.id,
            text: affair.name,
          };
        })
        .filter((affair) => affair.value !== this.affairForm.id);
    },
    ...mapGetters([
      "isLoadingUser",
      "usersList",
      "isLoadingCollaboratorsList",
      "isLoadingAffair",
      "collaboratorsList",
      "isLoadingCompany",
      "companiesList",
      "affairCategoriesTreeList",
      "affairsList",
      "isCreatingAffair",
      "isUpdatingAffair",
    ]),
  },
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BFormSpinbutton,
    Autocomplete,
    DateRangePicker,
    TreeSelect,
  },
  directives: {
    Ripple,
    mask,
  },
};
</script>
<style lang="scss">
.content-scrollable-sticky {
  // display: grid;
  // grid-template-columns: min-content 1fr;
  position: relative;
  .content-scrollable-sticky-nav {
    white-space: nowrap;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li a {
        display: block;
        padding: 1rem 0rem;
        color: white;
        text-decoration: none;
      }
      li a.current {
        background: black;
      }
    }
  }
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  .content-scrollable-sticky-nav ul {
    position: sticky;
    top: 0;
  }
}
</style>
